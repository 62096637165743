import js from "./images/js.png";
import ts from "./images/typescript.png";
import java from "./images/java.png";
import c from "./images/c.png";
import kotlin from "./images/kotlin.png";
import python from "./images/python.png";
import reactjs from "./images/reactjs.png";
import nodejs from "./images/nodejs.png";
import laravel from "./images/laravel.png";
import electronjs from "./images/electronjs.png";
import firebase from "./images/firebase.png";
import mongodb from "./images/mongodb.png";
import mysql from "./images/mysql.png";
import redis from "./images/redis.png";
import heroku from "./images/heroku.png";
import githubActions from "./images/githubActions.png";
import jest from "./images/jest.png";
import netlify from "./images/netlify.svg";
import gdg from "./images/gdg.jpg";
import github from "./images/github.jpg";
import openemp from "./images/openemp.png";
import meduim from "./images/meduim.png";
import coursera from "./images/coursera.png";
//import jmeter from "./images/jmeter.png";
import kafka from "./images/Apache_kafka.jpeg";
import nginx from "./images/nginx.png";
import fastapi from "./images/FastAPI.jpg";
import svelte from "./images/sveltelogo.png";
import docker from "./images/docker.png";

export const contentAboutMe = [
  [
    { imgLink: c, title: "", desc: "" },
    { imgLink: java, title: "", desc: "" },
    { imgLink: js, title: "", desc: "" },
    { imgLink: ts, title: "", desc: "" },
    { imgLink: python, title: "", desc: "" },
    { imgLink: kotlin, title: "", desc: "" },
  ],
  [
    { imgLink: reactjs, title: "", link: "https://reactjs.org/" },
    { imgLink: svelte, title: "", link: "https://svelte.dev/" },
    { imgLink: nodejs, title: "", link: "https://nodejs.org/en/" },
    { imgLink: jest, title: "", link: "https://jestjs.io/" },
    { imgLink: laravel, title: "", link: "https://laravel.com/" },
    { imgLink: electronjs, title: "", link: "https://www.electronjs.org/" },
    //{ imgLink: jmeter, title: "", link: "https://jmeter.apache.org/" },
    { imgLink: firebase, title: "", link: "https://firebase.google.com/" },
    { imgLink: mongodb, title: "", link: "https://www.mongodb.com/" },
    { imgLink: mysql, title: "", link: "https://www.mysql.com/" },
    { imgLink: redis, title: "", link: "https://redis.io/" },
    { imgLink: heroku, title: "", link: "https://www.heroku.com/" },
    { imgLink: netlify, title: "", link: "https://www.netlify.com/" },
    { imgLink: githubActions, title: "", link: "https://github.com/features/actions" },
    { imgLink: kafka, title: "", link: "https://kafka.apache.org/" },
    { imgLink: nginx, title: "", link: "https://www.nginx.com/" },
    { imgLink: fastapi, title: "", link: "https://fastapi.tiangolo.com/" },
    { imgLink: docker, title: "", link: "https://www.docker.com/" },
  ],
  [
    {
      imgLink: gdg,
      title: "GDG Chlef",
      desc:
        "Google Developers Group Chlef, Algeria comprises a group of developers who are fascinated by all the technological advances the world is nowadays witnessing.",
    },
    {
      imgLink: github,
      title: "Infobrains",
      desc:
        "The mission of the Informatics Club, is to influence, motivate, and educate students using creative, and enjoyable innovative parameters.",
    },
    {
      imgLink: reactjs,
      title: "React Arabic Community",
      desc:
        "Joining the great community and contributing in translating it documentation to arabic.",
    },
    {
      imgLink: openemp,
      title: "OpenEMP",
      desc:
        "An Open source Education Management Platform for Schools, Universities Designed to assist administrators, students, and instructors, to easily manage their respective workflow.",
    },
  ],
  // [
  //   {
  //     title: 'Load Balancing',
  //     desc: 'Using nodejs with Express web server or Using NGINX with Round-robin, Ip Hash and Least connections methods'
  //   }
  // ]
];
export const contentProjectsAmProudOf = [
  // TODO restore it
  //   {
  //   title: "Todos",
  //   desc:
  //     "A simple todo app. Made using Flutter and sqlite",
  //   link: "https://play.google.com/store/apps/details?id=merah.soheyb.todos",
  // },
  {
    title: "Power C",
    desc:
      "Implementing most popular methods and algorithms to increase C developers productivity, by using build in methods. Made using C",
    link: "https://github.com/splimter/Power-C",
  },
  {
    title: "Matheco",
    desc:
      "Matheco is a programing language along with its compiler, it solves some basic basic math problem, it also has extensions for vs code and atom supporting syntax highlight and snippets. Made using C, Flex and Bison",
    link: "https://github.com/splimter/snippet-matheco",
  },
  {
    title: "ASM Simulator",
    desc:
      "When I was studying computer architects, we used to draw a table that represent how the assembly manage instructions. Made using java.",
    link: "https://github.com/splimter/Univ/tree/master/ASM%20Simulator",
  },
  // {
  //   title: "JQ to JS",
  //   desc:
  //     "Convert your Jquery code to plain Javascript. Made using JS and NodeJs.",
  //   link: "https://jq-to-js.netlify.com/",
  // },
  {
    title: "SPAS",
    desc:
      "Student Performance Analysis System a platform where student, teacher and HOD can manage their tasks online. Made Using Laravel and MySql.",
    link: "https://phpspas.herokuapp.com/",
  },
];
export const contentMyActivities = [
  [
    {
      date: "November 2022 - July 2025",
      place: "IPB - Portugal",
      title: "Enrolling Master Degree",
      desc: [
        "Effects of speed in the control process of a line tracking robot", "Machine learning / Reinforcement learning"
      ],
    },
    {
      date: "May 2022 - October 2023",
      place: "StartAppZ - Jordan",
      title: "Joining as a Backend Developer",
      desc: [
        "Python, Redis, Svelte", "Elixir, Phoenix Framework, Honeybadger"
      ],
    },
    {
      date: "July 2022",
      place: "UHBC - Chlef",
      title: "Obtaining Master Diploma in System Information",
      desc: [
        "Heart Attack Predition Using Machine Learning.",
        "Combining multiple ML models such as XGboost LightGBM using Ensemble method.",
        "Web application build using Svelte/Flask.",
      ],
    },
    {
      date: "Feb 2022 - Aug 2022",
      place: "ScienceWings - Canada",
      title: "Joining as a Backend Developer",
      desc: [
        "Typescript", "Docker", "Postgress"
      ],
    },
    {
      date: "October 2020",
      place: "UHBC - Chlef",
      title: "Obtaining Bachelor Diploma in System Information",
      desc: [
        "Image Classification Using Machine Learning Technique: Case of study clothing classification.",
        "ML model made using python with tensorlow and keras library.",
        "Mobile application made using java.",
      ],
    },
    {
      date: "September 2020",
      place: "FNI - Algiers",
      title: "Bachelore Internship",
      desc: ["Facial recognition using HAAR and OpenCV."],
    }
  ],
  [
    {
      imgLink: meduim,
      title: "My Microservices Approach",
      desc: "https://splimter.medium.com/microservices-using-nestjs-4ea5f1580d32"
    },
    {
      imgLink: meduim,
      title: "Fetch api with ReactJs",
      desc:
        "https://medium.com/@soheybemir/fetch-api-with-reactjs-43ea38dd3b0b",
    },
    {
      imgLink: meduim,
      title: "Quick start with flex and bison",
      desc:
        "https://medium.com/@soheybemir/quick-start-with-flex-bison-19ab53f36d75?sk=6076bfd9a480018116908b743a246096",
    },
    {
      imgLink: meduim,
      title: "Quick start Mongo with Deployd",
      desc:
        "https://medium.com/@soheybemir/quick-start-mongo-with-deployd-294fe9ac7c12?sk=8ca2dc98fd2643580b339a38d555fcac",
    },
    {
      imgLink: meduim,
      title: "Quick guide to implement a Machine Learning model",
      desc:
        "https://soheybemir.medium.com/first-step-to-implement-a-machine-learning-model-24ec7099d1fe",
    },
  ],
  [
    {
      imgLink: coursera,
      title: "Coursera IBM Data Science",
      desc: "https://coursera.org/share/45932cc65f807239761adbf2ccef27f2",
    },
    {
      imgLink: coursera,
      title: "Software Design and Architecture",
      desc: "https://coursera.org/share/5c69f2efcf2ab96c81b222682daad25c",
    },
    {
      imgLink: coursera,
      title: "Open Source Software Development",
      desc: "https://coursera.org/share/9f49e9fb03cf8e4a45548715f1dd56b4",
    },
  ],
];
